export const listKundenanlagenbetreibers = /* GraphQL */ `
    query ListKundenanlagenbetreibers(
        $filter: ModelKundenanlagenbetreiberFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listKundenanlagenbetreibers(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                organisation_id
                KID
                kabStammdaten {
                    Adresse {
                        Land
                        Ort
                        PLZ
                        HausNummer
                        Postfach
                        Strasse
                        Zusatzinformationen
                    }
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                        }
                    }
                    AnzahlAnlagen
                    AktivAdresseID
                    Zusatzinfo {
                        Nachname
                        Vorname
                        Rechtsform
                        Finanzamt
                        Handelsregisternumer
                        Registergericht
                        Hauptzollamt
                    }
                    Bankdaten {
                        Kontoinhaber
                        BIC
                        IBAN
                    }
                }
                kabKontakt {
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                        }
                    }
                    Email
                    Rolle
                    Telefon
                    Zusatzinformationen
                }
                kabRechnungAdress {
                    Adresse {
                        Land
                        Ort
                        PLZ
                        HausNummer
                        Postfach
                        Strasse
                        Zusatzinformationen
                    }
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                        }
                    }
                }
                Metergrid {
                    project
                    url
                    id
                    location
                    data_provider
                    data_provider_ref
                    name
                    users
                }
                kundenanlageList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        KAname
                        Stichtag
                        Adresse {
                            Land
                            Ort
                            PLZ
                            HausNummer
                            Postfach
                            Strasse
                            Zusatzinformationen
                        }
                        Abrechnungsmodell
                        Vertragsbeginn
                        Vertragsende
                        isMetergridProject
                        Identifikationsnummer
                        Verteilnetzbetreiber
                        CodenummerVNB
                        Metergrid {
                            project
                            url
                            id
                            location
                            data_provider
                            data_provider_ref
                            name
                            users
                        }

                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const kundenanlagenbetreiberByKID = /* GraphQL */ `
    query KundenanlagenbetreiberByKID(
        $KID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelKundenanlagenbetreiberFilterInput
        $limit: Int
        $nextToken: String
    ) {
        kundenanlagenbetreiberByKID(
            KID: $KID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                KID
                kabStammdaten {
                    Adresse {
                        Land
                        Ort
                        PLZ
                        HausNummer
                        Postfach
                        Strasse
                        Zusatzinformationen
                    }
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                        }
                    }
                    AnzahlAnlagen
                    AktivAdresseID
                    Zusatzinfo {
                        Nachname
                        Vorname
                        Rechtsform
                        Finanzamt
                        Handelsregisternumer
                        Registergericht
                        Hauptzollamt
                    }
                    Bankdaten {
                        Kontoinhaber
                        BIC
                        IBAN
                    }
                }
                kabKontakt {
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                        }
                    }
                    Email
                    Rolle
                    Telefon
                    Zusatzinformationen
                }
                kabRechnungAdress {
                    Adresse {
                        Land
                        Ort
                        PLZ
                        HausNummer
                        Postfach
                        Strasse
                        Zusatzinformationen
                    }
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                        }
                    }
                }
                Metergrid {
                    project
                    url
                    id
                    location
                    data_provider
                    data_provider_ref
                    name
                    users
                }
                kundenanlageList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        KAname
                        Stichtag
                        Adresse {
                            Land
                            Ort
                            PLZ
                            HausNummer
                            Postfach
                            Strasse
                            Zusatzinformationen
                        }
                        Abrechnungsmodell
                        Vertragsbeginn
                        Vertragsende
                        isMetergridProject
                        Identifikationsnummer
                        Verteilnetzbetreiber
                        CodenummerVNB
                        Metergrid {
                            project
                            url
                            id
                            location
                            data_provider
                            data_provider_ref
                            name
                            users
                        }
                        erzeugungsanlageList {
                            nextToken
                        }
                        inbetriebnahmeprozess {
                            nextToken
                        }
                        kontaktpersonList {
                            nextToken
                        }
                        zaehlerplatzList {
                            nextToken
                        }
                        dokumenteList {
                            nextToken
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;


export const customListKundenanlagenbetreibers = `
    query ListKundenanlagenbetreibers(
        $filter: ModelKundenanlagenbetreiberFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listKundenanlagenbetreibers(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                kundenanlageList {
                    items {
                        zaehlerplatzList {
                            items {
                                KID
                                Lage
                                Malo
                                Melo
                                WohnungNr
                                ZID
                                id
                                geraeteList {
                                    items {
                                      AID
                                      ZID
                                      id
                                      organisation_id
                                      Seriennummer
                                      Einbaudatum
                                      Ausbaudatum
                                      ablesungList {
                                        items {
                                          Ablesedatum
                                          Ablesegrund
                                          Strombezugstand
                                          ZaehlerstandHT
                                          Einspeisungstand
                                        }
                                      }
                                    }
                                 }
                            }
                        }
                        AID
                        KAname
                        KID
                        id
                    }
                }
                id
                kabStammdaten {
                    Anrede {
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                        }
                        Typ
                    }
                }
                KID
                organisation_id
            }
            nextToken
        }
    }
`;

export const GetSlimData =`query GetSlimData {
  getSlimData {
    data_base64
    filename
    format
  }
}`;