export const CONSTANTS = {
    idToken: 'id_token',
    tokenExpire: 'expires_in',
    accessToken: 'access_token',
    refreshToken: 'refresh_token',
    domain: 'domain',
};
export const DokumentKategorie = [
    { label: 'Fertigstellungsanzeige des Zählerkastens', value: 'Fertigstellungsanzeige' },
    {
        label: 'Inbetriebstellungsanzeige der Erzeugungsanlage',
        value: 'Inbetriebstellungsanzeige',
    },
    { label: 'Foto vom geöffneten Zählerschrank', value: 'FotoZaehlerschrank' },
    { label: 'Kundenanlagenbestätigung vom VNB', value: 'Kundenanlagenbestaetigung' },
    { label: 'Vertrag', value: 'Vertrag' },
    { label: 'Abschlag', value: 'Abschlag' },
    { label: 'Rechnung', value: 'Rechnung' },
    { label: 'Sonstige', value: 'Sonstige' },
    { label: 'Messkonzept', value: 'Messkonzept' },
    { label: 'Auftragsverarbeitungsvertrag', value: 'Auftragsverarbeitungsvertrag' },
];
/*export const AblesegrundList = [
    { value: 'Turnusablesung', label: 'Turnusablesung' },
    { value: 'Mieterwechsel', label: 'Mieterwechsel' },
    { value: 'Produktwechsel', label: 'Produktwechsel' },
    { value: 'Einbau', label: 'Einbau' },
    { value: 'Ausbau', label: 'Ausbau' },
    { value: 'Sonstige', label: 'Sonstige' },
];*/

export enum AblesegrundEnums {
    Einbau = 'Einbau',
    Turnusablesung = 'Turnusablesung',
    Mieterwechsel = 'Mieterwechsel',
    Produktwechsel = 'Produktwechsel',
    Ausbau = 'Ausbau',
    Sonstige = 'Sonstige',
}

export enum SB_ZAEHLER_TYP{
    WANDLER_MESSUNG='Wandlermessung (250 Ampere)',
    DIREKT_MESSUNG='Direktmessung (Basiszähler 80 Ampere)'

}
export enum MetergridReasonMapping {
    Turnusablesung = 'REGULAR_CYLCE',
    Mieterwechsel = 'CONSUMER_CHANGE',
    Produktwechsel = 'NONE',
    Einbau = 'INSTALLATION',
    Ausbau = 'REMOVAL',
    Sonstige = 'NONE',
    Vertragsstart = 'PLAN_CHANGE',
}
