import axios from 'axios';
import { IAuftrag } from '../../../../common/interfaces/interface';
import {
    addLoadingState,
    removeLoadingState,
    setError,
    setSnackbarStatus,
} from '../../../../common/redux/actions/global.action';
import { SlimKontaktRollen } from '../../../../model/enums';
import { IReduxState } from '../../../../model/redux-state';
import { AuftragActionTypes } from '../../../auftrag/redux/types/auftrag.types';
import { getFacilityEmail } from '../../../facilities/redux/thunk/facility.operations';
import { SlimKabAnlageType } from '../../interfaces/slim.interface';
import {
    saveAnlageKontaktperson,
    saveSelectedSlimCustomers,
    saveSelectedSlimKundenAnlage,
    saveSlimAnlageGeraete,
    saveSlimAnlageSensors,
    saveSlimAnlageZaehlerplatz, saveSlimCSV,
    saveSlimCustomers,
} from '../actions/slim.actions';
import { slimAxios } from '../index';
import { AblesegrundEnums, MetergridReasonMapping } from '../../../../model/constants';
import {
    createSlimSensor,
    listSlimSensor,
    updateSlimSensor,
} from '../../slim-api/SensorApi/sensor.api';
import {
    createMGAblesung,
    createSlimAblesung,
    updateSlimAblesung,
} from '../../slim-api/ablesung-api/ablesung.api';
import {
    createMGZaehler,
    createSlimDevice,
    fetchGeraeteByAID,
    updateSlimDevice,
} from '../../slim-api/geraete-api/geraete.api';
import {
    getZaehlerplatzByAID,
    updateZaehlerplatzById,
} from '../../slim-api/zaehlerplatz-api/zaehlerplatz.api';
import { getKundenanlageByAID } from '../../slim-api/kundenanlage-api/kundenanlage.api';
import {
    fetchAllSlimKab,
    getKundenanlagenbetreiberByKID,
} from '../../slim-api/kundenanlagenbetreibers-api/kundenanlagenbetreibers.api';
import { getKontaktpersonByAID } from '../../slim-api/kontaktperson-api/kontaktperson-api';
import { PAGES_LIMIT } from '../../common/constant';
import {
    customListKundenanlagenbetreibers,
    listKundenanlagenbetreibers,
} from '../../slim-api/kundenanlagenbetreibers-api/graphql-operations/kundenanlagenbetreibers.queries';

export const getObiscode = value => {
    if (!value) {
        return '1-0:1.8.0';
    }
    if ('1-1:1.8.0') {
        return '1-0:1.8.0';
    }
    if ('1-1:1.8.1') {
        return '1-0:1.8.1';
    }
    return value;
};

export const getSlimKunden = () => {
    return async dispatch => {
        const Loading = 'GetSlimKunden';
        try {
            dispatch(addLoadingState(Loading));

            const allKabs = await fetchAllSlimKab(listKundenanlagenbetreibers, PAGES_LIMIT, null);

            dispatch(saveSlimCustomers({ items: [...allKabs], nextToken: null }));
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            // dispatch(saveCustomers([]));
            console.error(error);

            dispatch(setError({ message: error.errors[0].message ? error.errors[0].message : '', title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const fetchSlimKundeById = KID => {
    return async dispatch => {
        const Loading = 'FetchSlimKundeById';
        try {
            dispatch(addLoadingState(Loading));
            const response = await getKundenanlagenbetreiberByKID(KID);
            dispatch(saveSelectedSlimCustomers(response || null));
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(saveSelectedSlimCustomers(null));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const fetchSlimKundenAnlagenByAID = AID => {
    return async dispatch => {
        const Loading = 'FetchSlimKundenAnlagenById';
        try {
            dispatch(addLoadingState(Loading));
            const selectedFacility = await getKundenanlageByAID(AID);
            dispatch(saveSelectedSlimKundenAnlage(selectedFacility || null));
            dispatch(getFacilityEmail(selectedFacility.Adresse.PLZ));
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            dispatch(setError({ message: JSON.stringify(error), title: 'Error' }));
            dispatch(saveSelectedSlimKundenAnlage(null));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const fetchSlimKAKontaktperson = AID => {
    return async dispatch => {
        const Loading = 'FetchSlimKAKontaktperson';
        try {
            dispatch(addLoadingState(Loading));
            const response = await getKontaktpersonByAID(AID);
            dispatch(saveAnlageKontaktperson(response));
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(saveAnlageKontaktperson([]));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const fetchSlimKAZaehlerplatz = AID => {
    return async dispatch => {
        const Loading = 'METERPANELS_Loading';
        try {
            /* const filter = {
                Nutzungstyp: {
                    eq: 'Verbraucherzaehler',
                },
            };*/
            dispatch(addLoadingState(Loading));
            const response = await getZaehlerplatzByAID(AID);
            dispatch(saveSlimAnlageZaehlerplatz(response || { items: [] }));
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(removeLoadingState(Loading));
            dispatch(saveSlimAnlageZaehlerplatz([]));
        }
    };
};

export const postSlimAuftrag = (payload: IAuftrag) => {
    return async (dispatch, getState: () => IReduxState) => {
        dispatch(addLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
        try {
            const partners = getState().slimState.AnlageKontaktperson.items.find(
                item => item.Rolle === SlimKontaktRollen.AnsprechpartnerKA,
            );
            const installateurs = getState().slimState.AnlageKontaktperson.items.find(
                item => item.Rolle === SlimKontaktRollen.Elektroinstallateur,
            );
            if (partners && installateurs) {
                const response = await axios.post(`auftrag`, payload);
                dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
                return response.data;
            } else {
                dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
                dispatch(
                    setError({
                        title: 'Achtung!',
                        message:
                            'Es muss einen Ansprechpartner und einen Installateur vorhanden sein',
                    }),
                );
                return { ID: null };
            }
        } catch (error) {
            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
        }
    };
};

export const sendEmailSlimAuftrag = (
    payload: IAuftrag,
    auftragID: string,
    meterplaces: Array<any>,
    deviceMeterpanels: Array<{ device: any; meterplace: any }>,
) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            const partners = getState().slimState.AnlageKontaktperson.items.find(
                item => item.Rolle === SlimKontaktRollen.AnsprechpartnerKA,
            );
            const installateurs = getState().slimState.AnlageKontaktperson.items.find(
                item => item.Rolle === SlimKontaktRollen.Elektroinstallateur,
            );
            const kabContactPerson = getState().slimState.selectedSlimCustomer.kabKontakt;
            const slimKab = getState().slimState.selectedSlimCustomer;

            const auftragData: any = {
                AuftragID: auftragID,
                KAB: { ...slimKab.kabKontakt, ...slimKab.kabStammdaten },
                Email: payload.Email,
                Anlage: getState().slimState.selectedSlimKundenAnlage,
                Type: payload.Type,
                Ansprechpartner: partners,
                Installateur: installateurs,
                Bemerkung: payload.Bemerkung ? payload.Bemerkung : ' ',
                Wunschdatum: payload.Ausfuehrungsdatum ? payload.Ausfuehrungsdatum : ' ',
                Meterplaces: meterplaces,
                DeviceMeterplaces: deviceMeterpanels,
                KabContactPerson: kabContactPerson,
            };
            await axios.post(`auftrag/sendauftrag`, auftragData);
            dispatch(
                setSnackbarStatus({ flag: true, text: 'E-Mail wurde gesendet', type: 'success' }),
            );
        } catch (error) {
            dispatch(setError({ message: error.message, title: 'Error' }));
            console.error('error: ', error);
            dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
            dispatch(
                setSnackbarStatus({
                    flag: true,
                    text: 'E-Mail wurde nicht gesendet',
                    type: 'error',
                }),
            );
        }
    };
};

export const postSlimDevices = (kundenAnlagen: any, zaehlerplatzList, payload: any) => {
    return async dispatch => {
        const Loading = 'postSlimDevices';
        try {
            dispatch(addLoadingState(Loading));

            for (const device of payload) {
                const { Ablesungen, ...restDevice } = device;
                const newDevice = await createSlimDevice(restDevice);
                const ablesungPayload = Ablesungen[0];
                const newAblesung = await createSlimAblesung({
                    ...ablesungPayload,
                    Unit: 'kwh',
                    organisation_id: newDevice.organisation_id,
                    geraet_id: newDevice.id,
                    Seriennummer: newDevice.Seriennummer,
                    ZID: newDevice.ZID,
                    ZaehlerTyp: 'Mieterstromzaehler',
                });

                // push device to meter grid
                const slimPanel = zaehlerplatzList.find(item => item.ZID === device.ZID);

                const measuringPoint = await createMGZaehler({
                    serial: device.Seriennummer,
                    panel: slimPanel.Metergrid.url,
                    conversion_factor: device.Wandlerfaktor || 1,
                    project: kundenAnlagen.Metergrid.url,
                });

                // push ablesung-api to metergrid

                const measuringPointData = await createMGAblesung({
                    measuring_point: measuringPoint['url'] || '',
                    obis_code: getObiscode(device.ObisCodeHT), // obis_code
                    timestamp: new Date(ablesungPayload.Ablesedatum).toISOString(), // date iso conform string 12h
                    value: (ablesungPayload.ZaehlerstandHT || 0).toString(),
                    project: kundenAnlagen.Metergrid.url, // projecturl
                    reason: MetergridReasonMapping[ablesungPayload.Ablesegrund] || 'NONE',
                });
                // update slim device
                await dispatch(
                    updateSlimDevicesList([{
                        id: newDevice.id, Metergrid: {
                            url: measuringPoint.url,
                            id: measuringPoint.id,
                            serial: measuringPoint.serial,
                            project: measuringPoint.project,
                        },
                    }]),
                );
                // update slim ablesung-api
                await updateSlimAblesung({
                    id: newAblesung['id'],
                    Metergrid: {
                        id: measuringPointData.id,
                        measuring_point: measuringPointData.measuring_point,
                        project: measuringPointData.project,
                    },
                });
            }

            dispatch(removeLoadingState(Loading));
        } catch (error) {
            console.error(error);

            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            // dispatch(saveCustomers([]));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const getSlimDevices = (AID: string) => {
    return async dispatch => {
        const Loading = 'getSlimDevices';
        try {
            dispatch(addLoadingState(Loading));
            const filter = {
                ZaehlerTyp: {
                    eq: 'Mieterstromzaehler',
                },
            };
            const response = await fetchGeraeteByAID(AID, filter);
            dispatch(saveSlimAnlageGeraete(response));
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            // dispatch(saveCustomers([]));

            dispatch(setError({ message: JSON.stringify(error), title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const updateSlimMeterpanel = (payload: any[]) => {
    return async dispatch => {
        const Loading = 'updateSlimMeterpanel';
        try {
            dispatch(addLoadingState(Loading));

            for (const panel of payload) {
                await updateZaehlerplatzById(panel);
            }

            dispatch(removeLoadingState(Loading));
        } catch (error) {
            console.error(error);
            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            // dispatch(saveCustomers([]));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const updateSlimDevicesList = (payload: any[]) => {
    return async dispatch => {
        const Loading = 'updateSlimDevices';
        try {
            dispatch(addLoadingState(Loading));
            for (const device of payload) {
                await updateSlimDevice(device);
            }

            dispatch(removeLoadingState(Loading));
        } catch (error) {
            console.error(error);
            // dispatch(saveCustomers([]));

            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const updateSlimKundenAnlage = (payload: SlimKabAnlageType) => {
    return async dispatch => {
        const Loading = 'updateSlimKundenAnlage';
        try {
            dispatch(addLoadingState(Loading));
            const config = {
                headers: {
                    'x-api-key': process.env.REACT_APP_Slim_API_KEY,
                },
            };
            await slimAxios.put(`kabs/${payload.KID}/anlagen/${payload.ID}`, payload, config);
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            // dispatch(saveCustomers([]));

            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const ausbauSlimDevices = (selectedSlimKundenAnlage, devices: any[]) => {
    return async dispatch => {
        const Loading = 'ausbauAblesung';
        try {
            dispatch(addLoadingState(Loading));
            for (const device of devices) {
                // create ablesung-api
                const newAblesung = await createSlimAblesung({
                    Ablesedatum: device.Ausbaudatum,
                    ZaehlerstandHT: device.ZaehlerstandHT,
                    Ablesegrund: AblesegrundEnums.Ausbau,
                    Status: device.AusbaustandStatus || '',
                    Unit: 'kWh',
                    organisation_id: device.organisation_id,
                    geraet_id: device.id,
                    Seriennummer: device.Seriennummer,
                    ZID: device.ZID,
                    ZaehlerTyp: 'Mieterstromzaehler',
                });
                // push ablesung-api to metergrid
                const measuringPointData = await createMGAblesung({
                    measuring_point: device.Metergrid['url'] || '',
                    obis_code: getObiscode(device.ObisCodeHT), // obis_code
                    timestamp: new Date(device.Ausbaudatum).toISOString(), // date iso conform string 12h
                    value: (device.ZaehlerstandHT || 0).toString(),
                    project: selectedSlimKundenAnlage.Metergrid.url, // projecturl
                    reason: MetergridReasonMapping[AblesegrundEnums.Ausbau] || 'NONE',
                });
                // update slim ablesung-api
                await updateSlimAblesung({
                    id: newAblesung['id'],
                    Metergrid: {
                        id: measuringPointData.id,
                        measuring_point: measuringPointData.measuring_point,
                        project: measuringPointData.project,
                    },
                });
                dispatch(removeLoadingState(Loading));
            }
        } catch (error) {
            console.error(error);

            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const postSlimSensors = (sensors: any[]) => {
    return async dispatch => {
        const Loading = 'postSlimSensors';
        try {
            dispatch(addLoadingState(Loading));
            const response = await Promise.all(sensors.map(item => createSlimSensor(item)));
            dispatch(removeLoadingState(Loading));
            return response;
        } catch (e) {
            console.error(e);
            dispatch(setError({ message: 'Error', title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const updatedSlimSensors = (sensors: any[]) => {
    return async dispatch => {
        const Loading = 'updatedSlimSensors';
        try {
            dispatch(addLoadingState(Loading));
            const response = await Promise.all(sensors.map(item => updateSlimSensor(item)));
            dispatch(removeLoadingState(Loading));
            return response;
        } catch (e) {
            console.error(e);
            dispatch(setError({ message: 'Error', title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};

export const fetchSlimSensorByKundenanlageId = kundenanlageId => {
    return async dispatch => {
        const Loading = 'FetchSlimKundeById';
        try {
            dispatch(addLoadingState(Loading));
            const response = await listSlimSensor(kundenanlageId);
            // console.log(response);
            dispatch(
                saveSlimAnlageSensors(
                    response.data.sensorByKundenanlageId || { items: [], nextToken: null },
                ),
            );
            dispatch(removeLoadingState(Loading));
        } catch (error) {
            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            // dispatch(saveSelectedSlimCustomers(null));
            dispatch(removeLoadingState(Loading));
        }
    };
};


export const getSlimCSV = () => {
    return async dispatch => {
        const Loading = 'GetSlimCSV';
        try {
            dispatch(addLoadingState(Loading));
            const allKabs = await fetchAllSlimKab(customListKundenanlagenbetreibers, 100, null);

            dispatch(saveSlimCSV([...allKabs]));
            dispatch(removeLoadingState(Loading));
            return allKabs;
        } catch (error) {
            // dispatch(saveCustomers([]));
            console.error(error);

            dispatch(setError({ message: error.errors[0].message, title: 'Error' }));
            dispatch(removeLoadingState(Loading));
        }
    };
};
